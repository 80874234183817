<template>
  <aside :class="{ open: isOpen, collapsed: isCollapsed }">
    <div class="logo">
      <i class="fas fa-chart-pie"></i>
      <span v-if="!isCollapsed">额度网页H5</span>
    </div>
    <ul>
      <li
        v-for="item in menuItems"
        :key="item.name"
        :class="{ active: isActive(item.path) }"
      >
        <router-link
          :to="item.path"
          class="menu-item"
          @click="closeMenuOnMobile"
        >
          <i :class="item.icon"></i>
          <span v-if="!isCollapsed">{{ item.name }}</span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: [
        { name: "主页", path: "/admin", icon: "fas fa-home" },
        { name: "表单", path: "/form", icon: "fas fa-file-alt" },
        { name: "二维码", path: "/qrcode", icon: "fas fa-qrcode" },
        {
          name: "还款期数",
          path: "/repayment-periods",
          icon: "fas fa-calendar-alt",
        },
        {
          name: "行业/职位",
          path: "/industry-position",
          icon: "fas fa-briefcase",
        },
        {
          name: "贷款用途",
          path: "/loan-purpose",
          icon: "fas fa-hand-holding-usd",
        },
        {
          name: "人物关系",
          path: "/personal-relationships",
          icon: "fas fa-users",
        },
         {
          name: "参数配置",
          path: "/parameter-configurations",
          icon: "fas fa-info",
        },
      ],
    };
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    },
    closeMenuOnMobile() {
      if (window.innerWidth <= 768) {
        this.$emit("toggleMenu");
      }
    },
  },
};
</script>

<style scoped>
aside {
  width: 250px;
  background-color: #333;
  color: #fff;
  position: fixed;
  height: 100%;
  overflow: auto;
  transition: transform 0.3s ease, width 0.3s ease;
  z-index: 10;
}

.logo {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 18px;
}

.logo i {
  margin-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 15px;
  display: flex;
  align-items: center;
}

li i {
  margin-right: 10px;
}

li.active {
  background-color: #444;
}

.menu-item {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  width: 100%;
}

.open {
  transform: translateX(0);
}

.collapsed {
  width: 60px;
}

@media (max-width: 768px) {
  aside {
    transform: translateX(-120%);
  }
  .open {
    transform: translateX(0);
  }
}
</style>
