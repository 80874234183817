<template>
  <div class="password-change-container">
    <h2>修改密码</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="current-password">当前密码</label>
        <input
          type="password"
          id="current-password"
          v-model="currentPassword"
          required
        />
      </div>

      <div class="form-group">
        <label for="new-password">新密码</label>
        <input
          type="password"
          id="new-password"
          v-model="newPassword"
          @input="validatePassword"
          required
        />
        <small v-if="passwordError">{{ passwordError }}</small>
      </div>

      <div class="form-group">
        <label for="confirm-password">确认新密码</label>
        <input
          type="password"
          id="confirm-password"
          v-model="confirmPassword"
          @input="checkPasswordMatch"
          required
        />
        <small v-if="passwordMatchError">{{ passwordMatchError }}</small>
      </div>

      <button type="submit" :disabled="!isFormValid">提交</button>
    </form>
    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
  </div>
</template>
<script>
import { ref, computed } from "vue";
import axios from "@/axios";
import CustomAlert from "@/components/CustomAlert.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    CustomAlert,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const currentPassword = ref("");
    const newPassword = ref("");
    const confirmPassword = ref("");
    const passwordError = ref("");
    const passwordMatchError = ref("");
    const alertMessage = ref("");
    const alertType = ref("");
    const showAlert = ref(false);

    const isFormValid = computed(() => {
      return (
        newPassword.value &&
        confirmPassword.value &&
        !passwordError.value &&
        !passwordMatchError.value
      );
    });

    const validatePassword = () => {
      const hasUpperCase = /[A-Z]/.test(newPassword.value);
      const hasLowerCase = /[a-z]/.test(newPassword.value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value);
      const hasNumber = /[0-9]/.test(newPassword.value);
      const isLongEnough = newPassword.value.length >= 8;

      if (!isLongEnough) {
        passwordError.value = "密码长度不少于8个字符";
      } else if (!hasUpperCase) {
        passwordError.value = "密码必须包含至少一个大写字母";
      } else if (!hasLowerCase) {
        passwordError.value = "密码必须包含至少一个小写字母";
      } else if (!hasSpecialChar) {
        passwordError.value = "密码必须包含至少一个特殊字符";
      } else if (!hasNumber) {
        passwordError.value = "密码必须包含至少一个数字";
      } else {
        passwordError.value = "";
      }
    };

    const checkPasswordMatch = () => {
      if (newPassword.value !== confirmPassword.value) {
        passwordMatchError.value = "两次输入的密码不一致";
      } else {
        passwordMatchError.value = "";
      }
    };

    const showCustomAlert = (message, type = "info") => {
      alertMessage.value = message;
      alertType.value = type;
      showAlert.value = true;
      setTimeout(() => {
        showAlert.value = false;
      }, 3000);
    };

    const submitForm = async () => {
      if (isFormValid.value) {
        const req = {
          newPassword: newPassword.value,
          oldPassword: currentPassword.value,
        };
        try {
          const response = await axios.post("/changeMyPassword", req);
          if (response.status === 200) {
            showCustomAlert(response.data.message, "success");
            localStorage.removeItem("loggedIn");
            store.dispatch("logout");
            router.push("/login");
          } else {
            showCustomAlert(response.data.error, "error");
          }
        } catch (error) {
          showCustomAlert(error.response?.data?.error || "提交失败", "error");
          console.error("提交失败:", error);
          if (error.response?.data?.error === "登录过期") {
            localStorage.removeItem("loggedIn");
            store.dispatch("logout");
            router.push("/login");
          }
        }
      }
    };

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      passwordError,
      passwordMatchError,
      alertMessage,
      alertType,
      showAlert,
      isFormValid,
      validatePassword,
      checkPasswordMatch,
      showCustomAlert,
      submitForm,
    };
  },
};
</script>


<style scoped>
.password-change-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

small {
  color: red;
}
</style>
