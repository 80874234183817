<template>
  <div
    class="login-container"
    :style="{ backgroundImage: `url(${background})` }"
  >
    <div class="login-box">
      <h1>额度网页H5</h1>
      <p class="welcome-text">欢迎回来，请登录您的账号</p>
      <div class="input-group">
        <i class="fas fa-user"></i>
        <input type="text" v-model="loginForm.username" placeholder="用户名" />
      </div>
      <div class="input-group">
        <i class="fas fa-lock"></i>
        <input type="password" v-model="loginForm.password" placeholder="密码" />
      </div>
      <div class="options">
        <label>
          <input type="checkbox" v-model="rememberMe" />
          记住我
        </label>
        <button @click="login">登录 ☞</button>
      </div>
    </div>
     <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
    <LoadingSpinner :visible="loading" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import background from "@/assets/background.jpeg";
import axios from '@/axios';
import CustomAlert from '@/components/CustomAlert.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const showAlert = ref(false);
const alertMessage = ref('');
const alertType = ref('');

const router = useRouter();
const loading = ref(false);

const showCustomAlert = (message, type = 'info') => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const loginForm = ref({
  username: '',
  password: ''
});

const store = useStore();

const rememberMe = ref(false);

const login = async() => {
  try {
    const response = await axios.post('/login', loginForm.value);
    if(response.status == 200){
      showCustomAlert(response.data.message,'success');
      const token = response.data.token;
      store.dispatch('login', token);
      localStorage.setItem("loggedIn",true);
      loading.value = true;
      
      setTimeout(() => {
        loading.value = false;
        router.push("/admin");
      }, 3000);
      //window.location.reload();
    }else{
       showCustomAlert(response.data.error,'error');
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "登录失败", 'error');
    console.error('登录失败:', error);
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-position: center center;
  background-size: cover;
}

.login-box {
  /* font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif; */
  /* background-color: hwb(0 100% 0%) !important; */
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
  /* Apply the Facebook-like font */
}

.login-box h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.welcome-text {
  color: #888;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.input-group input {
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box; /* Ensure the input box doesn’t overflow */
  transition: box-shadow 0.3s, border-color 0.3s;
}

.input-group input:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Apply shadow on focus */
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.options button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options button:hover {
  background-color: #45a049;
}
</style>
