<template>
  <div v-if="visible" :class="['custom-alert', type]" @click="close">
    <i :class="iconClass" class="alert-icon"></i>
    <span>{{ message }}</span>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue';

const props = defineProps({
  message: String,
  type: {
    type: String,
    default: 'info' // can be 'success', 'error', 'warning', 'info'
  },
  duration: {
    type: Number,
    default: 3000
  }
});

const visible = ref(true);

const close = () => {
  visible.value = false;
};

const iconClass = computed(() => {
  switch (props.type) {
    case 'success':
      return 'fas fa-check-circle';
    case 'error':
      return 'fas fa-exclamation-circle';
    case 'warning':
      return 'fas fa-exclamation-triangle';
    case 'info':
    default:
      return 'fas fa-info-circle';
  }
});

onMounted(() => {
  setTimeout(() => {
    close();
  }, props.duration);
});
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.custom-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
}

.custom-alert .alert-icon {
  margin-right: 10px;
  font-size: 18px;
}

.custom-alert.info {
  background-color: #2196f3;
}

.custom-alert.success {
  background-color: #4caf50;
}

.custom-alert.error {
  background-color: #d35656;
}

.custom-alert.warning {
  background-color: #e4ac57;
}
</style>
