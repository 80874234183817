<template>
  <div class="spinner-overlay" v-if="visible">
    <div class="spinner"></div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  visible: {
    type: Boolean,
    default: true
  }
});
console.log(props);
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.spinner {
  width: 100px;
  height: 100px;
  border: 9px solid rgba(0, 0, 0, 0.1);
  border-top: 12px solid #529b54;
  border-radius: 60%;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 10px #dde6de, 0 0 20px #d5dad5, 0 0 30px #929692, 0 0 40px hsl(120, 1%, 28%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
