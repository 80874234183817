<template>
  <header>
    <div class="left-section">
      <div class="toggle-menu" @click="$emit('toggleMenu')">
        <i class="fas fa-bars"></i>
      </div>
    </div>
    <div class="right-section">
      <div class="status">
        <span>{{ username }}</span>
        <span :class="{ online: isOnline, offline: !isOnline }"></span>
        <button @click="toggleLogoutMenu">▼</button>
        <ul v-if="showLogout" class="logout-menu">
          <li @click="$emit('logout')">
            <i class="fas fa-sign-out-alt"></i> 退出
          </li>
          <li @click="changePassword"><i class="fas fa-key"></i> 修改密码</li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";


export default {
  props: {
    username: {
      type: String,
      required: true,
    },
    isOnline: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const showLogout = ref(false);
    const router = useRouter();
    const toggleLogoutMenu = () => {
      showLogout.value = !showLogout.value;
    };

    const changePassword = () => {
      router.push("/change");
    };

    return {
      showLogout,
      toggleLogoutMenu,
      changePassword,
    };
  },
};
</script>

<style scoped>
header {
  padding: 15px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 5;
}

.left-section {
  display: flex;
  align-items: center;
}

.toggle-menu {
  padding: 10px;
  cursor: pointer;
}

.right-section {
  display: flex;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
}

.status span {
  margin-right: 10px;
}

.online {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
}

.offline {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.logout-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 15%;
  list-style: none;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-menu li {
  padding: 5px;
  cursor: pointer;
}

.logout-menu li {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout-menu li i {
  margin-right: 10px;
}
</style>
