import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import MainContent from '../components/MainContent.vue';
import Form from '../components/MainForm.vue';
import QRCode from '../components/QRCode.vue';
import RepaymentPeriods from '../components/RepaymentPeriods.vue';
import IndustryPosition from '../components/IndustryPosition.vue';
import LoanPurpose from '../components/LoanPurpose.vue';
import PersonalRelationships from '../components/PersonalRelationships.vue';
import ParameterConfiguration from "../components/ParameterConfiguration.vue"
import Register from '../components/UserRegist.vue';
import ChangePassword from '../components/ChangePassword.vue';

const routes = [
    {
        path: '/',
        name: 'Index',
        component: MainContent,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: MainContent,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: UserLogin
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    { path: '/form', name: 'Form', component: Form, meta: { requiresAuth: true } },
    { path: '/qrcode', name: 'QRCode', component: QRCode, meta: { requiresAuth: true } },
    { path: '/repayment-periods', name: 'RepaymentPeriods', component: RepaymentPeriods, meta: { requiresAuth: true } },
    { path: '/industry-position', name: 'IndustryPosition', component: IndustryPosition, meta: { requiresAuth: true } },
    { path: '/loan-purpose', name: 'LoanPurpose', component: LoanPurpose, meta: { requiresAuth: true } },
    { path: '/personal-relationships', name: 'PersonalRelationships', component: PersonalRelationships, meta: { requiresAuth: true } },
    { path: '/parameter-configurations', name: 'ParameterConfigurations', component: ParameterConfiguration, meta: { requiresAuth: true } },
    { path: '/change', name: 'ChangePassword', component: ChangePassword, meta: { requiresAuth: true } }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('loggedIn');
    if (to.matched.some(record => record.meta.requiresAuth) && loggedIn == null) {
        next('/login');
    } else {
        next();
        if (to.fullPath === "/admin" && from.fullPath === "/login") {
            setTimeout(() => {
                window.location.reload();
            }, 200);
        }
    }
});

export default router;
