<template>
  <div class="main-form">
     <!-- 加载指示器 -->
    <div v-if="isLoading" class="loading-bar">
      <div class="loading-bar-progress"></div>
    </div>
    <div class="header">
      <h1>
        表单
        <h3>{{ pageTitle }}</h3>
      </h1>
      <div class="toolbar1" v-if="!isListView">
        <button class="add-btn" @click="toggleView">
          <span><i class="fas fa-list"></i> 列表</span>
        </button>
      </div>
      <div class="toolbar" v-if="isListView">
        <button class="filter-btn" @click="toggleFilter">
          <i class="fas fa-filter"></i> 筛选
        </button>
        <div class="search-bar">
          <i class="fas fa-search" @click="search()"></i>
          <input
            type="text"
            v-model="searchQuery"
            placeholder="公司、银行卡号、城市、开户行"
          />
        </div>
      </div>
    </div>
    <!-- 筛选侧边栏 -->
    <div
      :class="['filter-sidebar', { 'filter-sidebar-open': isFilterOpen }]"
      v-if="!isMobile && isListView"
    >
      <div class="filter-header">
        <button @click="resetFilter"><i class="fas fa-redo"></i> 重置</button>
        <button @click="applyFilter"><i class="fas fa-search"></i> 搜索</button>
      </div>
      <div class="filter-body">
        <label><i class="fas fa-building"></i> &nbsp;公司</label>
        <input type="text" v-model="filter.company" placeholder="公司" />

        <label><i class="fas fa-envelope"></i> &nbsp;邮箱</label>
        <input type="text" v-model="filter.email" placeholder="邮箱" />

        <label><i class="fas fa-industry"></i> &nbsp;行业/职位</label>
        <input
          type="text"
          v-model="filter.industryPosition"
          placeholder="行业/职位"
        />

        <label><i class="fas fa-city"></i> &nbsp;城市</label>
        <input type="text" v-model="filter.city" placeholder="城市" />

        <label><i class="fas fa-credit-card"></i> &nbsp;银行卡号</label>
        <input
          type="text"
          v-model="filter.bankCardNumber"
          placeholder="银行卡号"
        />

        <label><i class="fas fa-university"></i> &nbsp;开户行</label>
        <input type="text" v-model="filter.bankName" placeholder="开户行" />

        <label><i class="fas fa-hand-holding-usd"></i> &nbsp;还款方式</label>
        <select v-model="filter.repaymentMethod" placeholder="选择">
          <option value="等额本息">等额本息</option>
          <option value="按月付息到期还本">按月付息到期还本</option>
        </select>

        <label><i class="fas fa-calendar-alt"></i> &nbsp;还款期数</label>
        <input
          type="number"
          v-model="filter.repaymentPeriods"
          placeholder="还款期数"
        />

        <label><i class="fas fa-money-bill-wave"></i> &nbsp;额度 (从-到)</label>
        <input
          type="number"
          v-model="filter.minCreditLimit"
          placeholder="最低额度"
        />
        <input
          type="number"
          v-model="filter.maxCreditLimit"
          placeholder="最高额度"
        />

        <label><i class="fas fa-dollar-sign"></i> &nbsp;提款金额 (从-到)</label>
        <input
          type="number"
          v-model="filter.minWithdrawalAmount"
          placeholder="最低金额"
        />
        <input
          type="number"
          v-model="filter.maxWithdrawalAmount"
          placeholder="最高金额"
        />

        <label
          ><i class="fas fa-calendar-alt"></i> &nbsp;创建时间 (从-到)</label
        >
        <input type="date" v-model="filter.startDate" placeholder="开始时间" />
        <input type="date" v-model="filter.endDate" placeholder="结束时间" />
      </div>
    </div>

    <!-- 手机端的筛选弹出层 -->
    <div v-if="isMobile && isFilterOpen && isListView" class="mobile-filter">
      <div class="mobile-filter-header">
        <button @click="resetFilter"><i class="fas fa-redo"></i> 重置</button>
        <button @click="applyFilter"><i class="fas fa-search"></i> 搜索</button>
      </div>
      <div class="mobile-filter-body">
        <div>
          <label><i class="fas fa-building"></i> </label>
          <input type="text" v-model="filter.company" placeholder="公司" />
        </div>
        <div>
          <label><i class="fas fa-envelope"></i> </label>
          <input type="text" v-model="filter.email" placeholder="邮箱" />
        </div>
        <div>
          <label><i class="fas fa-industry"></i> </label>
          <input
            type="text"
            v-model="filter.industryPosition"
            placeholder="行业/职位"
          />
        </div>
        <div>
          <label><i class="fas fa-city"></i> </label>
          <input type="text" v-model="filter.city" placeholder="城市" />
        </div>
        <div>
          <label><i class="fas fa-credit-card"></i> </label>
          <input
            type="text"
            v-model="filter.bankCardNumber"
            placeholder="银行卡号"
          />
        </div>
        <div>
          <label><i class="fas fa-university"></i></label>
          <input type="text" v-model="filter.bankName" placeholder="开户行" />
        </div>
        <div>
          <label><i class="fas fa-hand-holding-usd"></i></label>
          <select v-model="filter.repaymentMethod">
            <option value="还款方式">还款方式</option>
            <option value="等额本息">等额本息</option>
            <option value="按月付息到期还本">按月付息到期还本</option>
          </select>
        </div>
        <div>
          <label><i class="fas fa-calendar-alt"></i></label>
          <input
            type="number"
            v-model="filter.repaymentPeriods"
            placeholder="还款期数"
          />
        </div>
        <div>
          <label><i class="fas fa-money-bill-wave"></i></label>
          <input
            type="number"
            v-model="filter.minCreditLimit"
            placeholder="最低额度"
          />
        </div>
        <div>
          <label><i class="fas fa-money-bill-wave"></i></label>
          <input
            type="number"
            v-model="filter.maxCreditLimit"
            placeholder="最高额度"
          />
        </div>
        <div>
          <label><i class="fas fa-dollar-sign"></i></label>
          <input
            type="number"
            v-model="filter.minWithdrawalAmount"
            placeholder="最低金额"
          />
        </div>
        <div>
          <label><i class="fas fa-dollar-sign"></i></label>
          <input
            type="number"
            v-model="filter.maxWithdrawalAmount"
            placeholder="最高金额"
          />
        </div>
        <div>
          <label><i class="fas fa-calendar-alt"></i>创建开始时间</label>
          <input
            type="date"
            v-model="filter.startDate"
            placeholder="开始时间"
          />
        </div>
        <div>
          <label><i class="fas fa-calendar-alt"></i>创建结束时间</label>
          <input type="date" v-model="filter.endDate" placeholder="结束时间" />
        </div>
      </div>
      <button class="close-mobile-filter" @click="toggleFilter">X</button>
    </div>

    <div class="table-container" v-if="isListView">
      <table class="data-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>银行卡号</th>
            <th v-if="!isMobile">邮箱</th>
            <th v-if="!isMobile">开户行</th>
            <th v-if="!isMobile">公司</th>
            <th v-if="!isMobile">行业职位</th>
            <th v-if="!isMobile">城市</th>
            <th v-if="!isMobile">还款方式</th>
            <th v-if="!isMobile">还款期数</th>
            <th v-if="!isMobile">年化(100%)</th>
            <th v-if="!isMobile">额度</th>
            <th v-if="!isMobile">提款金额</th>
            <th v-if="!isMobile">用途</th>
            <th v-if="!isMobile">身份证人像</th>
            <th v-if="!isMobile">身份证国徽</th>
            <th v-if="!isMobile">创建时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in filteredAndPaginatedData" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.bankCardNumber }}</td>
            <td v-if="!isMobile">{{ row.email }}</td>
            <td v-if="!isMobile">{{ row.bankName }}</td>
            <td v-if="!isMobile">{{ row.company }}</td>
            <td v-if="!isMobile">{{ row.industryPosition }}</td>
            <td v-if="!isMobile">{{ row.city }}</td>
            <td v-if="!isMobile">{{ row.repaymentMethod }}</td>
            <td v-if="!isMobile">{{ row.repaymentPeriods }}</td>
            <td v-if="!isMobile">{{ row.annualRate }}</td>
            <td v-if="!isMobile">{{ row.creditLimit }}</td>
            <td v-if="!isMobile">{{ row.withdrawalAmount }}</td>
            <td v-if="!isMobile">{{ row.purpose }}</td>
            <td v-if="!isMobile">
              <img
                :src="row.idCardFront"
                alt="身份证人像"
                @click="enlargeImage(row.idCardFront)"
              />
            </td>
            <td v-if="!isMobile">
              <img
                :src="row.idCardBack"
                alt="身份证国徽"
                @click="enlargeImage(row.idCardBack)"
              />
            </td>
            <td v-if="!isMobile">{{ row.createdAt }}</td>
            <td>
              <button v-if="isMobile" @click="showDetail(row)">
                <i class="fas fa-eye"></i>
              </button>
              <div v-if="!isMobile">
                <button @click="editRow(row)">
                  <i class="fas fa-edit"></i>
                </button>
                <button @click="deleteRow(row.id)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination" v-if="isListView">
      <div>
        <label class="showPage" for="pageSize">每页显示:</label>
        <select v-model="pageSize" id="pageSize">
          <option value="10">10条</option>
          <option value="20">20条</option>
          <option value="30">30条</option>
          <option value="50">50条</option>
        </select>
      </div>
      <button v-if="!isMobile" @click="prevPage" :disabled="currentPage === 1">
        上一页
      </button>
      <button v-if="isMobile" @click="prevPage" :disabled="currentPage === 1">
        ◀
      </button>
      <div class="jump">
        跳转到<input
          type="number"
          v-model.number="gotoPage"
          min="1"
          :max="totalPages"
          @change="jumpToPage"
        />页
      </div>
      <button
        v-if="!isMobile"
        @click="nextPage"
        :disabled="currentPage >= totalPages"
      >
        下一页
      </button>
      <button
        v-if="isMobile"
        @click="nextPage"
        :disabled="currentPage >= totalPages"
      >
        ▶
      </button>
      <div>
        <span class="page-info"
          >第 {{ currentPage }} 页,共 {{ totalPages }} 页</span
        >
      </div>
    </div>

    <div v-if="isDetailOpen && isListView" class="detail-modal">
      <div class="detail-content">
        <button class="close-detail" @click="isDetailOpen = false">X</button>
        <table>
          <tr v-for="(value, key) in selectedRow" :key="key">
            <td>{{ keyTranslations[key] || key }}</td>
            <td>{{ value }}</td>
          </tr>
        </table>
        <div class="actions">
          <button @click="editRow(selectedRow)">
            <i class="fas fa-edit"></i>
          </button>
          <button @click="deleteRow(selectedRow.id)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="detail" v-if="isCreating">
      <form @submit.prevent="submitForm">
        <br />
        <hr class="myhr" />
        <br />
        <div class="row">
          <div class="control-label"><i class="fas fa-id-badge"></i>ID:</div>
          <div class="box-body">
            <input type="text" v-model="formData.id" disabled />
          </div>
        </div>

        <!-- 公司 -->
        <div class="row">
          <div class="control-label"><i class="fas fa-building"></i>公司:</div>
          <div class="box-body">
            <input type="text" v-model="formData.company" required />
          </div>
        </div>

        <div class="row">
          <div class="control-label"><i class="fas fa-briefcase"></i>职位:</div>
          <div class="box-body">
            <input type="text" v-model="formData.industryPosition" required />
          </div>
        </div>

        <!-- 城市 -->
        <div class="row">
          <div class="control-label"><i class="fas fa-city"></i>城市:</div>
          <div class="box-body">
            <input type="text" v-model="formData.city" required />
          </div>
        </div>
        <!-- 邮箱 -->
        <div class="row">
          <div class="control-label"><i class="fas fa-envelope"></i>邮箱:</div>
          <div class="box-body">
            <input type="email" v-model="formData.email" required />
          </div>
        </div>

        <!-- 银行卡号 -->
        <div class="row">
          <div class="control-label">
            <i class="fas fa-credit-card"></i>银行卡:
          </div>
          <div class="box-body">
            <input type="number" v-model="formData.bankCardNumber" required />
          </div>
        </div>

        <!-- 开户行 -->
        <div class="row">
          <div class="control-label">
            <i class="fas fa-university"></i> 开户行:
          </div>
          <div class="box-body">
            <input type="text" v-model="formData.bankName" required />
          </div>
        </div>

        <!-- 地址 -->
        <div class="row">
          <div class="control-label">
            <i class="fas fa-map-marker-alt"></i>地址:
          </div>
          <div class="box-body">
            <input type="text" v-model="formData.address" required />
          </div>
        </div>

        <!-- 身份证人像 -->
        <div class="row">
          <div class="control-label">
            <i class="fas fa-id-badge"></i>身份证人像:
          </div>
          <div class="box-body">
            <input
              type="file"
              @change="handleFileChange($event, 'idPortrait', 'front')"
            />
            <img
              v-if="formData.idPortrait"
              :src="formData.idPortrait"
              alt="身份证人像"
            />
          </div>
        </div>

        <!-- 身份证国徽 -->
        <div class="row">
          <div class="control-label">
            <i class="fas fa-shield-alt"></i>身份证国徽:
          </div>
          <div class="box-body">
            <input
              type="file"
              @change="handleFileChange($event, 'idEmblem', 'back')"
            />
            <img
              v-if="formData.idEmblem"
              :src="formData.idEmblem"
              alt="身份证国徽预览"
            />
          </div>
        </div>

        <hr class="myhr" />

        <div class="row">
          <div class="control-label">
            <i class="fas fa-user-friends">联系人信息:</i>
          </div>
          <div class="box-body1">
            <table>
              <thead>
                <tr>
                  <th>关系</th>
                  <th>姓名</th>
                  <th>手机号</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(contact, index) in contacts" :key="index">
                  <td>
                    <input
                      type="text"
                      v-model="contact.relationship"
                      required
                    />
                  </td>
                  <td>
                    <input type="text" v-model="contact.name" required />
                  </td>
                  <td>
                    <input type="tel" v-model="contact.phoneNumber" required />
                  </td>
                  <td>
                    <button
                      v-if="contact.id"
                      @click="removeContact(contact.id)"
                      type="button"
                    >
                      删除
                    </button>
                    <button
                      v-else
                      @click="
                        addContact1(
                          contact.relationship,
                          contact.phoneNumber,
                          contact.name
                        )
                      "
                      type="button"
                    >
                      添加
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button @click="addContact" type="button" class="add">
              添加联系人
            </button>
          </div>
        </div>

        <hr class="myhr" />
        <br />
        <br />

        <!-- 还款方式 -->
        <div class="row">
          <div class="control-label">还款方式:</div>
          <div class="box-body">
            <input
              type="radio"
              id="equalPrincipalInterest"
              value="等额本息"
              v-model="formData.repaymentMethod"
            />
            <label for="equalPrincipalInterest">等额本息</label>
            <input
              type="radio"
              id="monthlyInterest"
              value="按月付息到期还本"
              v-model="formData.repaymentMethod"
            />
            <label for="monthlyInterest">按月付息到期还本</label>
          </div>
        </div>

        <!-- 下面的字段 -->
        <div class="row">
          <!-- 用途 -->
          <div class="control-label">用途:</div>
          <div class="box-body">
            <input type="text" v-model="formData.usage" required />
          </div>
        </div>
        <div class="row">
          <!-- 还款期数 -->
          <div class="control-label">还款期数:</div>
          <div class="box-body">
            <input type="number" v-model="formData.repaymentPeriods" required />
          </div>
        </div>
        <div class="row">
          <!-- 年华(%) -->
          <div class="control-label">年化(%):</div>
          <div class="box-body">
            <input
              type="text"
              v-model="formData.annualPercentageRate"
              required
            />
          </div>
        </div>
        <div class="row">
          <!-- 额度 -->
          <div class="control-label">额度:</div>
          <div class="box-body">
            <input type="text" v-model="formData.creditLimitRange" required />
          </div>
        </div>
        <div class="row">
          <!-- 提款金额 -->
          <div class="control-label">提款金额:</div>
          <div class="box-body">
            <input
              type="number"
              v-model="formData.withdrawalAmountRange"
              required
            />
          </div>
        </div>
        <div class="row">
          <!-- 创建时间 -->
          <div class="control-label">创建时间:</div>
          <div class="box-body">
            <input type="date" v-model="formData.startDate" required />
          </div>
        </div>
        <div class="row">
          <!-- 更新时间 -->
          <div class="control-label">更新时间:</div>
          <div class="box-body">
            <input type="date" v-model="formData.endDate" required />
          </div>
        </div>

        <br />
        <hr class="myhr" />
        <br />
        <button type="submit"><i class="fas fa-check"></i>更新</button>
        <button @click="resetForm"><i class="fas fa-redo"></i>重置</button>
      </form>
    </div>

    <!-- 模态框，用于放大图片 -->
    <div v-if="isModalOpen" class="modal" @click="isModalOpen = false">
      <img :src="modalImage" alt="放大图片" />
    </div>
    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
    <CustomConfirm
      :isVisible="isConfirmVisible"
      title="确认删除"
      message="您确定要删除这条记录吗？此操作无法撤销。"
      @confirm="confirmDelete"
      @cancel="() => (isConfirmVisible = false)"
    />
     <CustomConfirm
      class="confirm"
      :isVisible="isConfirmVisible1"
      title="确认删除"
      message="您确定要删除这条记录吗？此操作无法撤销。"
      @confirm="confirmDelete1"
      @cancel="() => (isConfirmVisible1 = false)"
    />
    <!-- 返回顶部图标 -->
    <div v-if="showBackToTop" class="back-to-top" @click="scrollToTop">↑</div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";

import CustomAlert from "@/components/CustomAlert.vue";
import CustomConfirm from "@/components/CustomConfirm.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@/axios";

const store = useStore();
const router = useRouter();
const searchQuery = ref("");
const pageSize = ref(20);
const currentPage = ref(1);
const gotoPage = ref(1);
const isModalOpen = ref(false);
const modalImage = ref("");
const isMobile = ref(window.innerWidth <= 768);
const isDetailOpen = ref(false);
const selectedRow = ref({});
const showBackToTop = ref(false);
const isFilterOpen = ref(false);
const isCreating = ref(false);
const isListView = ref(true);

const filter = ref({
  company: "",
  email: "",
  industryPosition: "",
  city: "",
  bankCardNumber: "",
  bankName: "",
  repaymentMethod: "",
  repaymentPeriods: "",
  minCreditLimit: "",
  maxCreditLimit: "",
  minWithdrawalAmount: "",
  maxWithdrawalAmount: "",
  startDate: "",
  endDate: "",
});

const formData = ref({
  id: "",
  company: "",
  city: "",
  email: "",
  bankCardNumber: "",
  bankName: "",
  industryPosition: "",
  address: "",
  repaymentMethod: "",
  usage: "",
  repaymentPeriods: "",
  qrCode: null,
  annualPercentageRate: "",
  creditLimitRange: "",
  withdrawalAmountRange: "",
  startDate: "",
  endDate: "",
  idPortrait: null,
  idEmblem: null,
  front: null,
  back: null,
});

const contacts = ref([]);

const keyTranslations = {
  id: "编号",
  bankCardNumber: "银行卡号",
  bankName: "开户行",
  company: "公司",
  industryPosition: "行业职位",
  city: "城市",
  email: "邮箱",
  repaymentMethod: "还款方式",
  repaymentPeriods: "还款期数",
  annualRate: "年化(100%)",
  creditLimit: "额度",
  withdrawalAmount: "提款金额",
  purpose: "用途",
  idCardFront: "身份证人像",
  idCardBack: "身份证国徽",
  createdAt: "创建时间",
};

const pageTitle = ref("列表");

const tableData = ref([]);

const isConfirmVisible = ref(false);
const isConfirmVisible1 = ref(false);
const showAlert = ref(false);
const alertMessage = ref("");
const alertType = ref("");

const showCustomAlert = (message, type = "info") => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const isLoading = ref(false);

function addContact() {
  contacts.value.push({ name: "", phoneNumber: "", relationship: "" });
  console.log(contacts.value);
}

const fetchTableData = async () => {
   isLoading.value = true;
  try {
    const response = await axios.get("/details");
    if (response.status == 200) {
       if (response.data != null) {
        tableData.value = response.data;
      }else{
         tableData.value = [];
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }finally {
    isLoading.value = false; // 完成加载
  }
};

onMounted(fetchTableData);

const search = async () => {
  if (searchQuery.value == "") {
    fetchTableData();
  } else {
    try {
      const response = await axios.get(`/fuzzy?content=${searchQuery.value}`);
      if (response.status == 200) {
        if (response.data != null) {
          tableData.value = response.data;
        }
      } else {
        showCustomAlert(response.data.error, "error");
      }
    } catch (error) {
      showCustomAlert(error.response.data.error || "获取数据失败", "error");
      console.error("获取数据失败:", error);
      if (error.response.data.error == "登录过期") {
        localStorage.removeItem("loggedIn");
        store.dispatch("logout");
        router.push("/login");
      }
    }
  }
};

const filteredData = computed(() => {
  if (!searchQuery.value) {
    return tableData.value;
  }
  return tableData.value.filter(
    (row) =>
      row.bankCardNumber.includes(searchQuery.value) ||
      row.city.toString().includes(searchQuery.value) ||
      row.company.toString().includes(searchQuery.value) ||
      row.bankName.toString().includes(searchQuery.value)
  );
});

const filteredAndPaginatedData = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredData.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredData.value.length / pageSize.value);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const toggleFilter = () => {
  isFilterOpen.value = !isFilterOpen.value;
};

const toggleView = () => {
  isListView.value = !isListView.value;
  if (isListView.value) {
    pageTitle.value = "列表";
    isCreating.value = false;
    fetchTableData();
  } else {
    pageTitle.value = "创建";
    isCreating.value = true;
    resetForm();
  }
};

const resetForm = () => {
  formData.value = {
    id: formData.value.id,
    company: "",
    city: "",
    email: "",
    bankCardNumber: "",
    bankName: "",
    address: "",
    idPortrait: null,
    idEmblem: null,
    repaymentMethod: "",
    usage: "",
    repaymentPeriods: "",
    qrCode: null,
    annualPercentageRate: "",
    creditLimitRange: "",
    withdrawalAmountRange: "",
    startDate: "",
    endDate: "",
  };
};

const resetFilter = () => {
  filter.value = {
    company: "",
    email: "",
    industryPosition: "",
    city: "",
    bankCardNumber: "",
    bankName: "",
    repaymentMethod: "",
    repaymentPeriods: "",
    minCreditLimit: "",
    maxCreditLimit: "",
    minWithdrawalAmount: "",
    maxWithdrawalAmount: "",
    startDate: "",
    endDate: "",
  };
};

const applyFilter = async () => {
  try {
    const response = await axios.get(`/search`, { params: filter.value });
    if (response.status == 200) {
      if (response.data != null) {
        tableData.value = response.data;
      } else {
        tableData.value = [];
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

function handleFileChange(event, fieldName, fieldName2) {
  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    const reader = new FileReader();
    reader.onload = (e) => {
      formData.value[fieldName] = e.target.result;
      formData.value[fieldName2] = file;
    };
    reader.readAsDataURL(file);
  } else {
    showCustomAlert("请选择图片文件", "warning");
  }
}

const jumpToPage = () => {
  if (gotoPage.value >= 1 && gotoPage.value <= totalPages.value) {
    currentPage.value = gotoPage.value;
  }
};

const enlargeImage = (src) => {
  modalImage.value = src;
  isModalOpen.value = true;
};

const editRow = async (selectedRow) => {
  toggleView();
  formData.value.id = selectedRow.id;
  formData.value.company = selectedRow.company;
  formData.value.industryPosition = selectedRow.industryPosition;
  formData.value.city = selectedRow.city;
  formData.value.email = selectedRow.email;
  formData.value.bankCardNumber = selectedRow.bankCardNumber;
  formData.value.bankName = selectedRow.bankName;
  formData.value.idPortrait = selectedRow.idCardFront;
  formData.value.idEmblem = selectedRow.idCardBack;
  formData.value.repaymentMethod = selectedRow.repaymentMethod;
  formData.value.usage = selectedRow.purpose;
  formData.value.annualPercentageRate = selectedRow.annualRate;
  formData.value.withdrawalAmountRange = selectedRow.withdrawalAmount;
  formData.value.creditLimitRange = selectedRow.creditLimit;
  formData.value.address = selectedRow.detailedAddress;
  const date = new Date(selectedRow.createdAt);
  // 提取 YYYY-MM-DD 部分
  const formattedDate = date.toISOString().split("T")[0];
  formData.value.startDate = formattedDate;
  const date1 = new Date(selectedRow.updatedAt);
  // 提取 YYYY-MM-DD 部分
  const formattedDate1 = date1.toISOString().split("T")[0];
  formData.value.endDate = formattedDate1;
  formData.value.repaymentPeriods = selectedRow.repaymentPeriods;

  try {
    const response = await axios.get(`/contacts/${formData.value.id}`);
    if (response.status == 200) {
      if (response.data != null) {
        contacts.value = response.data;
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

const selectedRowId = ref("");
const selectedRowId1 = ref("");

const confirmDelete = async () => {
  // 删除逻辑
  try {
    const response = await axios.delete(`/contacts/${selectedRowId.value}`);
    if (response.status === 200) {
      showCustomAlert(response.data.message, "success");
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "删除失败", "error");
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
  try {
    const response = await axios.get(`/contacts/${formData.value.id}`);
    if (response.status == 200) {
      if (response.data != null) {
        contacts.value = response.data;
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

const confirmDelete1 = async()=> {
    // 删除逻辑
  try {
    const response = await axios.delete(`/details/${selectedRowId1.value}`);
    if (response.status === 200) {
      showCustomAlert(response.data.message, "success");
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "删除失败", "error");
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }finally{
    isConfirmVisible.value = false;
    isConfirmVisible1.value = false;
    selectedRowId.value = null;
    selectedRowId1.value = null;
    fetchTableData();
    isListView.value = true;
    pageTitle.value = "列表";
    isCreating.value = false;
  }
}

const removeContact = (id) => {
  selectedRowId.value = id;
  isConfirmVisible.value = true;
};

const addContact1 = async (relationship, phoneNumber, name) => {
  console.log(relationship, phoneNumber, name);
  var contracts = [];
  var contract = {
    relationship: relationship,
    name: name,
    phone_number: phoneNumber,
  };
  contracts.push(contract);
  var data1 = {
    userid: formData.value.id,
    contracts: contracts,
  };
  try {
    //上传
    const response1 = await axios.post("/emergency-contacts", data1);
    if (response1.status == 200) {
      showCustomAlert(response1.data.message, "success");
    } else {
      showCustomAlert(response1.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "提交失败", "error");
    console.error(error);
  }
};

const deleteRow = (id) => {
  selectedRowId1.value = id;
  isDetailOpen.value = false;
  isConfirmVisible1.value = true;
  console.log("删除行111:", id);
};

const showDetail = (row) => {
  selectedRow.value = row;
  isDetailOpen.value = true;
};

window.addEventListener("resize", () => {
  isMobile.value = window.innerWidth <= 768;
});

// 检测滚动事件
const handleScroll = () => {
  showBackToTop.value = window.scrollY > 200;
};

// 页面滚动到顶部
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const submitForm = async () => {
  const data = new FormData();
  data.append("id", formData.value.id);
  data.append("industry_position", formData.value.industryPosition);
  data.append("company", formData.value.company);

  data.append("residential_address", formData.value.city);
  data.append("email", formData.value.email);
  data.append("bank_account_number", formData.value.bankCardNumber);
  data.append("bank_name", formData.value.bankName);
  data.append("detailed_address", formData.value.address);

  data.append("loan_amount", formData.value.withdrawalAmountRange);
  data.append("repayment_periods", formData.value.repaymentPeriods);
  data.append("repayment_method", formData.value.repaymentMethod);
  data.append("purpose", formData.value.usage);
  data.append("annual_rate", formData.value.annualPercentageRate);
  data.append("credit_limit", formData.value.creditLimitRange);
  if(formData.value.front == null){
        data.append("id_card_front", "");
  }else{
    data.append("id_card_front", formData.value.front); 
  }
  if(formData.value.back == null){
 data.append("id_card_back", "");
  }else{
 data.append("id_card_back", formData.value.back);
  }
  
  try {
    const response = await axios.post("/details", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status == 200) {
      console.log(response);
      showCustomAlert(response.data.message, "success");
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "提交失败", "error");
    console.error(error);
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
.main-form {
  padding: 20px;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  color: #333;
  font-family: Montserrat, Nunito, sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.3;
  color: #2c2c2c;
}

.header h3 {
  margin: 0;
  color: rgb(141, 139, 139) !important;
  font-family: Montserrat, Nunito, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: #2c2c2c;
}

.toolbar {
  display: flex;
  align-items: center;
  width: 80%;
}

.toolbar1 {
  /* display: flex; */
  align-items: center;
}

.filter-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Arial", sans-serif;
}

.filter-btn i {
  margin-right: 5px;
}

.add-btn {
  align-items: center;
  background-color: #4caf50;
  color: white;
  border: none;
  float: right;
  padding: 10px;
  cursor: pointer;
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f3f4f6;
  z-index: 1000;
}

.loading-bar-progress {
  width: 0%;
  height: 100%;
  background-color: #3498db;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% { width: 0%; }
  50% { width: 50%; }
  100% { width: 100%; }
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  margin-left: 20px;
  flex-grow: 1;
}

.search-bar i {
  color: #888;
  margin-right: 5px;
}

.search-bar input {
  border: none;
  background: none;
  padding: 8px;
  outline: none;
  width: 100%;
  /* font-size: 16px; */
}

.table-container {
  overflow-x: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 增加阴影 */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgb(236, 240, 250);
  border-top: 0;
  border-right-width: 1px;
  border-left-width: 1px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  /* border: 1px solid #ddd; */
  border-spacing: 0;
  color: #414750;
  font-size: 13px;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif; /* Apply the Facebook-like font */
  z-index: 1; /* 增加z-index */
}

.data-table th {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  /* position: sticky; */
  top: 0;
  /* z-index: 1; */
}

.data-table tbody tr:hover {
  background-color: #f1f1f1;
}

.data-table th:first-child,
.data-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 5; /* 增加z-index 使其高于其他列 */
  background: #fff;
  border: 1px solid #ddd;
}

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
  position: sticky;
  left: 36px !important;
  background: #fff;
  z-index: 5; /* 增加z-index 使其高于其他列 */
  border: 1px solid #ddd;
}

.data-table th:last-child,
.data-table td:last-child {
  position: sticky;
  right: 0;
  background: #fff;
  z-index: 5; /* 增加z-index 使其高于其他列 */
  border: 1px solid #ddd;
}

.data-table img {
  max-width: 50px;
  cursor: pointer;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: #f4f4f4; /* 增加背景色 */
  padding: 10px; /* 增加内边距 */
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 增加阴影 */
  font-size: 14px; /* 调整字体大小 */
  color: #555; /* 字体颜色 */
}

.pagination label {
  margin-right: 10px;
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
}

.pagination select,
.pagination input {
  margin-right: 10px;
  padding: 5px 10px; /* 增加内边距 */
  border-radius: 4px; /* 圆角边框 */
  border: 1px solid #ccc; /* 边框颜色 */
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
  font-size: 12px; /* 调整字体大小 */
}

.pagination input {
  width: 60px;
  margin: 0;
}

.pagination button {
  padding: 5px 10px; /* 增加内边距 */
  border: 1px solid #ccc; /* 边框颜色 */
  border-radius: 4px; /* 圆角边框 */
  background: linear-gradient(to bottom, #ffffff, #f1f1f1); /* 渐变背景色 */
  cursor: pointer;
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
  font-size: 12px; /* 调整字体大小 */
  color: #555; /* 字体颜色 */
  transition: background 0.3s ease; /* 增加过渡效果 */
  margin-left: 3%;
  margin-right: 3%;
}

.pagination button:disabled {
  cursor: not-allowed;
  background: #e0e0e0; /* 禁用状态背景色 */
}

.pagination button:hover:not(:disabled) {
  background: linear-gradient(
    to bottom,
    #f1f1f1,
    #e1e1e1
  ); /* 鼠标悬停时背景色 */
}

.page-info {
  color: #888;
  font-size: 12px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

button i {
  color: #888;
  font-size: 18px;
}

button:hover i {
  color: #4caf50;
}

.confirm{
  z-index: 100;
}

/* 手机端适配样式 */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .toolbar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .search-bar {
    width: 100%;
    margin: 10px 0;
  }
  .pagination button {
    margin-top: 2%;
  }
  .filter-btn {
    padding: 5px 10px;
  }

  .pagination {
    flex-direction: row;
    align-items: flex-start;
  }
  .jump {
    display: none;
  }
  .showPage {
    display: none;
  }
  .page-info {
    color: #888;
    font-size: 12px;
  }

  .pagination label {
    margin: 5px 0;
  }

  .pagination select {
    padding: 2px 5px;
    margin: 3px 0;
  }
  .pagination button {
    padding: 1px 3px;
    margin-top: 2%;
  }
}

.detail-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  font-size: 12px;
  overflow-y: auto;
  z-index: 10;
}

.detail-content {
  background-color: #fff;
  padding: 10px;
  padding-top: 70%;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.detail-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.detail-content td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.close-detail {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.back-to-top:hover {
  background-color: #388e3c;
}

.filter-sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 25%;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  overflow-y: auto;
  z-index: 8;
  color: #414750;
  font-size: 12px;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif;
}

.filter-sidebar-open {
  right: 0;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 80%;
}
.filter-body {
  margin-bottom: 25px;
}

.filter-body label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 15px;
}

.filter-body input,
.filter-body select {
  width: 80%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px !important;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.mobile-filter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease-in-out;
  padding: 10px;
  overflow-y: auto;
  z-index: 8;
  font-size: 14px;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif;
}

.mobile-filter-header {
  margin-top: 10% !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 80%;
}

.mobile-filter-body {
  margin-bottom: 25px;
  flex-direction: column;
}

.mobile-filter-body label {
  padding-left: 10px;
  padding-bottom: 5px;
}

.mobile-filter-body input,
.mobile-filter-body select {
  width: 80%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 14px !important;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.close-mobile-filter {
  position: absolute;
  top: 30px;
  right: 20px;
  font-size: 15x;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  margin-right: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* 添加在<style scoped>内 */
.detail {
  display: flex;
  padding: 20px;
  background-color: rgb(236, 240, 250);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
  font-size: 14px;
  flex-direction: column;
}

.detail .row {
  display: flex;
  flex-direction: row; /* 设置行方向 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px;
}

.detail .control-label {
  width: 30%;
  margin-bottom: 10px;
  color: #333;
  text-align: right;
}

.detail .box-body {
  width: 50%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
}

.detail .box-body1 {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow-x: auto;
}

.box-body1 input {
  font-size: 14px !important;
  border: 0.5px solid gray;
  text-align: center;
}

.detail .box-body img {
  max-width: 100%;
  border-radius: 4px;
}

.detail .box-body input {
  width: 50%;
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 14px !important;
  border: none;
  /* border: 0.5px solid #ccc;
  border-radius: 15px; */
}

.detail button {
  background-color: #586cb1;
  color: #fff;
  border: none;
  border-radius: 6px;
  /* padding: 10px 12px; */
  margin: 5px;
  cursor: pointer;
  float: inherit;
  font-size: 13px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-left: 60%;
  /* position: relative;
  flex: 1 1 auto; */
}

.detail button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detail button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.detail button i {
  margin-right: 5px;
}

.box-body1 button {
  background-color: #586cb1;
  color: #fff;
  border: none;
  border-radius: 6px;
  /* padding: 10px 12px; */
  margin: 5px;
  cursor: pointer;
  float: inherit;
  font-size: 13px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-left: 0%;
}

.box-body1 .add {
  margin-left: 30%;
}

.detail .control-label div {
  display: inline;
}

.detail .control-label div.v-if {
  color: red;
}

.detail .actions {
  margin-top: 20px;
}

.control-label {
  padding-top: 10px;
  padding-right: 5px;
  margin-bottom: 0;
  text-transform: capitalize !important;
  position: relative;
  padding-left: 14px;
  display: inline-block;
  color: #2b3656;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.01rem;
  font-size: 15px;
}

.myhr {
  width: 100%;
  border: 0.5px solid #ddd;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

hr.dashed {
  border: 0;
  height: 1px;
  border-top: 1px dashed red; /* 设置虚线样式和颜色 */
}
@media (max-width: 768px) {
  .detail {
    padding-left: 0px;
  }
  .detail .control-label {
    width: 30%;
    padding-left: 0px;
    padding-right: 2px;
    font-size: 12px !important;
  }

  .detail .box-body {
    width: 70%;
    font-size: 12px !important;
  }
  .detail .box-body {
    /* width: 80%; */
    width: 100%;
  }
}
</style>
