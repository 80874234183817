<template>
  <div class="main-form">
     <!-- 加载指示器 -->
    <div v-if="isLoading" class="loading-bar">
      <div class="loading-bar-progress"></div>
    </div>
    <div class="header">
      <h1>
        人物关系
        <h3>{{ pageTitle }}</h3>
      </h1>
      <div class="toolbar">
        <button class="add-btn" @click="toggleView">
          <span v-if="isListView"><i class="fas fa-plus"></i> 新增</span>
          <span v-else><i class="fas fa-list"></i> 列表</span>
        </button>
      </div>
    </div>
    <div class="table-container" v-if="isListView">
      <table class="data-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>关系</th>
            <th @click="sortBy('importance')">
              重要性
              <i :class="getSortIcon('importance')"></i>
            </th>
            <th @click="sortBy('createdAt')">创建时间</th>
            <th @click="sortBy('updatedAt')">
              更新时间
              <i :class="getSortIcon('updatedAt')"></i>
            </th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in filteredAndPaginatedData" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.relationship }}</td>
            <td>
              <div v-if="!row.importanceEdit">{{ row.importance }}</div>
              <div v-else>
                <input type="number" v-model="row.importance" />
              </div>
              <button v-if="!row.importanceEdit" @click="editCell(row)">
                <i class="fas fa-edit"></i>
              </button>
              <button @click="saveCell(row)" v-else>
                <i class="fas fa-save"></i>
              </button>
            </td>
            <td>{{ row.createdAt }}</td>
            <td>{{ row.updatedAt }}</td>
            <td>
              <button @click="editRow(row.id)">
                <i class="fas fa-edit"></i>
              </button>
              <button @click="deleteRow(row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination" v-if="isListView">
      <div>
        <label class="showPage" for="pageSize">每页显示:</label>
        <select v-model="pageSize" id="pageSize">
          <option value="10">10条</option>
          <option value="20">20条</option>
          <option value="30">30条</option>
          <option value="50">50条</option>
        </select>
      </div>
      <button v-if="!isMobile" @click="prevPage" :disabled="currentPage === 1">
        上一页
      </button>
      <button v-if="isMobile" @click="prevPage" :disabled="currentPage === 1">
        ◀
      </button>
      <div class="jump">
        跳转到<input
          type="number"
          v-model.number="gotoPage"
          min="1"
          :max="totalPages"
          @change="jumpToPage"
        />页
      </div>
      <button
        v-if="!isMobile"
        @click="nextPage"
        :disabled="currentPage >= totalPages"
      >
        下一页
      </button>
      <button
        v-if="isMobile"
        @click="nextPage"
        :disabled="currentPage >= totalPages"
      >
        ▶
      </button>
      <div>
        <span class="page-info"
          >第 {{ currentPage }} 页,共 {{ totalPages }} 页</span
        >
      </div>
    </div>

    <div class="detail" v-else>
      <form v-if="isCreating" @submit.prevent="submitForm">
        <br />
        <hr class="myhr" />
        <br />
        <div class="row">
          <div class="control-label">
            <div style="color: red">*&nbsp;&nbsp;</div>
            关系
          </div>
          <div class="box-body">
            <input
              type="text"
              v-model="formData.relationship"
              placeholder="输入关系"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="control-label">
            <div style="color: red">*&nbsp;&nbsp;</div>
            排序
          </div>
          <div class="box-body">
            <input type="number" v-model="formData.importance" required />
          </div>
        </div>
        <br />
        <hr class="myhr" />
        <button type="submit"><i class="fas fa-save"></i>提交</button>
      </form>

      <div v-else>
        <form @submit.prevent="updateDetails">
          <br />
          <hr class="myhr" />
          <br />
          <div class="row">
            <div class="control-label">ID:</div>
            <div class="box-body">{{ details.id }}</div>
          </div>
          <div class="row">
            <div class="control-label">
              <div v-if="isEditing" style="color: red">*&nbsp;&nbsp;</div>
              关系:
            </div>
            <div class="box-body">
              <div v-if="!isEditing">{{ details.relationship }}</div>
              <div v-else>
                <input type="text" v-model="formData.relationship" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="control-label">
              <div v-if="isEditing" style="color: red">*&nbsp;&nbsp;</div>
              排序:
            </div>
            <div class="box-body">
              <div v-if="!isEditing">{{ details.importance }}</div>
              <div v-else>
                <input type="number" v-model="formData.importance" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="control-label">创建时间:</div>
            <div class="box-body">{{ details.createdAt }}</div>
          </div>
          <div class="row">
            <div class="control-label">更新时间:</div>
            <div class="box-body">{{ details.updatedAt }}</div>
          </div>
          <br />
          <hr class="myhr" />
          <br />
          <button @click="editDetails" v-if="!isEditing">
            <i class="fas fa-pencil-alt"></i>编辑
          </button>
          <button @click="deleteDetails" v-if="!isEditing">
            <i class="fas fa-trash"></i>删除
          </button>
          <button type="submit" v-if="isEditing">
            <i class="fas fa-check"></i>更新
          </button>
          <button @click="resetForm" v-if="isEditing">
            <i class="fas fa-redo"></i>重置
          </button>
        </form>
      </div>
    </div>
    <div v-if="showBackToTop" class="back-to-top" @click="backToTop">↑</div>
    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
    <CustomConfirm
      :isVisible="isConfirmVisible"
      title="确认删除"
      message="您确定要删除这条记录吗？此操作无法撤销。"
      @confirm="confirmDelete"
      @cancel="() => (isConfirmVisible = false)"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import CustomAlert from "@/components/CustomAlert.vue";
import CustomConfirm from "@/components/CustomConfirm.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@/axios";

const store = useStore();
const router = useRouter();

const tableData = ref([
]);
const isLoading = ref(false);
const searchQuery = ref("");
const pageSize = ref(10);
const currentPage = ref(1);
const sortKey = ref("");
const sortDirection = ref("asc");
const isMobile = ref(window.innerWidth <= 768);
const showBackToTop = ref(false);
const gotoPage = ref(1);
const pageTitle = ref("列表");
const isListView = ref(true);
const isCreating = ref(false);
const isEditing = ref(false);
const formData = ref({
  relationship: "",
  importance: 0,
});
const details = ref({
  id: "",
  relationship: "",
  importance: "",
  createdAt: "",
  updatedAt: "",
});

const selectedRowId = ref(null);
const isConfirmVisible = ref(false);
const showAlert = ref(false);
const alertMessage = ref("");
const alertType = ref("");

const showCustomAlert = (message, type = "info") => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const fetchTableData = async () => {
   isLoading.value = true;
  try {
    const response = await axios.get("/task-relationships");
    if (response.status == 200) {
      if (response.data != null) {
        tableData.value = response.data;
      }else{
         tableData.value = [];
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }finally {
    isLoading.value = false; // 完成加载
  }
};

onMounted(fetchTableData);

const editCell = (row) => {
  row.importanceEdit = true;
};

const saveCell = async (row) => {
  row.importanceEdit = false;
  var id = row.id;
  try {
    var req = {
      importance: row.importance,
    };
    const response = await axios.put(`/task-relationships-update/${id}`, req);
    if (response.status == 200) {
      showCustomAlert(response.data.message, "success");
      fetchTableData();
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "更新失败", "error");
    console.error("更新失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

const filteredData = computed(() => {
  if (!searchQuery.value) {
    return tableData.value;
  }
  return tableData.value.filter(
    (row) =>
      row.relationship.includes(searchQuery.value) ||
      row.id.toString().includes(searchQuery.value)
  );
});

const filteredAndPaginatedData = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredData.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredData.value.length / pageSize.value);
});

const toggleView = () => {
  isListView.value = !isListView.value;
  if (isListView.value) {
    pageTitle.value = "列表";
    isCreating.value = false;
    isEditing.value = false;
    fetchTableData();
  } else {
    pageTitle.value = "创建";
    isCreating.value = true;
    resetForm();
  }
};

const submitForm = async () => {
  if(formData.value.importance == 0){
    showCustomAlert("请输入排序",'warning');
    return;
  }
  pageTitle.value = "详细";
  isCreating.value = false;
  var req = {
    relationship: formData.value.relationship,
    importance: formData.value.importance,
  };
  try {
    const response = await axios.post("/task-relationships", req);
    if (response.status == 200) {
      showCustomAlert(response.data.message, "success");
      var data = {
        id: response.data.id,
        relationship: response.data.relationship,
        importance: response.data.importance,
        createdAt: response.data.created_at,
        updatedAt: response.data.created_at,
      };
      tableData.value.push(data);
      details.value = data;
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "提交失败", "error");
    console.error("提交失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

const confirmDelete = async () => {
  // 删除逻辑
  try {
    const response = await axios.delete(
      `/task-relationships/${selectedRowId.value}`
    );
    if (response.status === 200) {
      showCustomAlert(response.data.message, "success");
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "删除失败", "error");
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  } finally {
    isConfirmVisible.value = false;
    selectedRowId.value = null;
    fetchTableData();
    isListView.value = true;
    pageTitle.value = "列表";
    isCreating.value = false;
    isEditing.value = false;
  }
};

const updateDetails = async () => {
  if(formData.value.importance == 0){
    showCustomAlert("请输入排序",'warning');
    return;
  }
  isEditing.value = false;
  try {
    var req = {
      relationship: formData.value.relationship,
      importance: formData.value.importance,
    };
    const response = await axios.put(
      `/task-relationships/${details.value.id}`,
      req
    );
    if (response.status == 200) {
      showCustomAlert(response.data.message, "success");
      isEditing.value = false;
      details.value = {
        ...formData.value,
        updatedAt: new Date().toLocaleString(),
      };
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "更新失败", "error");
    console.error("更新失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

const editDetails = () => {
  isEditing.value = true;
  formData.value = { ...details.value };
};

const deleteDetails = () => {
  selectedRowId.value = details.value.id;
  isConfirmVisible.value = true;
};

const resetForm = () => {
  formData.value = {
    relationship: "",
    importance: 0,
  };
};

const editRow = (id) => {
  const row = tableData.value.find((row) => row.id === id);
  if (row) {
    isListView.value = false;
    pageTitle.value = "详细";
    isCreating.value = false;
    isEditing.value = true;
    details.value = { ...row };
    formData.value.relationship = row.relationship;
    formData.value.importance = row.importance;
    formData.value.createdAt = row.createdAt;
    formData.value.id = row.id;
    formData.value.updatedAt = row.updatedAt;
  }
};

const deleteRow = (id) => {
  selectedRowId.value = id;
  isConfirmVisible.value = true;
};

const sortBy = (key) => {
  if (sortKey.value === key) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortKey.value = key;
    sortDirection.value = "asc";
  }
  tableData.value.sort((a, b) => {
    let result = 0;
    if (a[key] < b[key]) result = -1;
    if (a[key] > b[key]) result = 1;
    return sortDirection.value === "asc" ? result : -result;
  });
};

const getSortIcon = (key) => {
  if (sortKey.value !== key) {
    return "fas fa-sort";
  }
  return sortDirection.value === "asc"
    ? "fas fa-arrow-up"
    : "fas fa-arrow-down";
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const jumpToPage = () => {
  if (gotoPage.value >= 1 && gotoPage.value <= totalPages.value) {
    currentPage.value = gotoPage.value;
  }
};

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
};

const handleScroll = () => {
  showBackToTop.value = window.scrollY > 300;
};

const backToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
.main-form {
  padding: 20px;
  background-color: white;
  font-family: "Nunito", "Montserrat", "system-ui",
    "BlinkMacSystemFont" "-apple-system", "sans-serif" !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  color: #333;
  font-family: Montserrat, Nunito, sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.3;
  color: #2c2c2c;
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f3f4f6;
  z-index: 1000;
}

.loading-bar-progress {
  width: 0%;
  height: 100%;
  background-color: #3498db;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% { width: 0%; }
  50% { width: 50%; }
  100% { width: 100%; }
}

.header h3 {
  margin: 0;
  color: rgb(141, 139, 139) !important;
  font-family: Montserrat, Nunito, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: #2c2c2c;
}

.toolbar {
  display: flex;
  align-items: center;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  margin-left: 20px;
  flex-grow: 1;
}

.search-bar i {
  color: #888;
  margin-right: 5px;
}

.search-bar input {
  border: none;
  background: none;
  padding: 8px;
  outline: none;
  width: 100%;
  /* font-size: 16px; */
}

.add-btn {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.table-container {
  overflow-x: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 增加阴影 */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgb(236, 240, 250);
  border-top: 0;
  border-right-width: 1px;
  border-left-width: 1px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  /* border: 1px solid #ddd; */
  border-spacing: 0;
  color: #414750;
  font-size: 13px;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif; /* Apply the Facebook-like font */
  z-index: 1; /* 增加z-index */
}

.data-table th {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  /* position: sticky; */
  top: 0;
  /* z-index: 1; */
}

.data-table tbody tr:hover {
  background-color: #f1f1f1;
}

.data-table select {
  /* display: block; */
  width: 120px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.data-table input {
  width: 50%;
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 14px !important;
  border: none;
}

.data-table select option {
  font-size: 14px;
  color: #333;
}

.qr-code-img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: #f4f4f4; /* 增加背景色 */
  padding: 10px; /* 增加内边距 */
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 增加阴影 */
  font-size: 14px; /* 调整字体大小 */
  color: #555; /* 字体颜色 */
}

.pagination label {
  margin-right: 10px;
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
}

.pagination select,
.pagination input {
  margin-right: 10px;
  padding: 5px 10px; /* 增加内边距 */
  border-radius: 4px; /* 圆角边框 */
  border: 1px solid #ccc; /* 边框颜色 */
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
  font-size: 12px; /* 调整字体大小 */
}

.pagination input {
  width: 60px;
  margin: 0;
}

.pagination button {
  padding: 5px 10px; /* 增加内边距 */
  border: 1px solid #ccc; /* 边框颜色 */
  border-radius: 4px; /* 圆角边框 */
  background: linear-gradient(to bottom, #ffffff, #f1f1f1); /* 渐变背景色 */
  cursor: pointer;
  font-family: "Nunito", "Montserrat", system-ui, BlinkMacSystemFont,
    -apple-system, sans-serif; /* 优雅的字体 */
  font-size: 12px; /* 调整字体大小 */
  color: #555; /* 字体颜色 */
  transition: background 0.3s ease; /* 增加过渡效果 */
  margin-left: 3%;
  margin-right: 3%;
}

.pagination button:disabled {
  cursor: not-allowed;
  background: #e0e0e0; /* 禁用状态背景色 */
}

.pagination button:hover:not(:disabled) {
  background: linear-gradient(
    to bottom,
    #f1f1f1,
    #e1e1e1
  ); /* 鼠标悬停时背景色 */
}

.page-info {
  color: #888;
  font-size: 12px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

button {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

button i {
  color: #888;
  font-size: 18px;
}

button:hover i {
  color: #4caf50;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
}

.detail-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  font-size: 12px;
  overflow-y: auto;
  z-index: 10;
}

.detail-content {
  background-color: #fff;
  padding: 10px;
  padding-top: 20%;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  z-index: 10;
}

/* 手机端适配样式 */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .toolbar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .pagination button {
    margin-top: 2%;
  }

  .search-bar {
    width: 100%;
    margin: 10px 0;
  }
  .filter-btn {
    padding: 5px 10px;
  }

  .pagination {
    flex-direction: row;
    align-items: flex-start;
  }
  .jump {
    display: none;
  }
  .showPage {
    display: none;
  }
  .page-info {
    color: #888;
    font-size: 12px;
  }

  .pagination label {
    margin: 5px 0;
  }

  .pagination select {
    padding: 2px 5px;
    margin: 3px 0;
  }
  .pagination button {
    padding: 1px 3px;
    margin-top: 2%;
  }
}

.detail-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.detail-content td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.close-detail {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.back-to-top:hover {
  background-color: #388e3c;
}

/* 添加在<style scoped>内 */
.detail {
  display: flex;
  padding: 20px;
  background-color: rgb(236, 240, 250);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
  font-size: 14px;
  flex-direction: column;
}

.detail .row {
  display: flex;
  flex-direction: row; /* 设置行方向 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px;
}

.detail .control-label {
  width: 30%;
  margin-bottom: 10px;
  color: #333;
  text-align: right;
}

.detail .box-body {
  width: 50%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
}

.detail .box-body img {
  max-width: 100%;
  border-radius: 4px;
}

.detail .box-body input {
  width: 50%;
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 14px !important;
  border: none;
  /* border: 0.5px solid #ccc;
  border-radius: 15px; */
}

.detail button {
  background-color: #586cb1;
  color: #fff;
  border: none;
  border-radius: 6px;
  /* padding: 10px 12px; */
  margin: 5px;
  cursor: pointer;
  float: inherit;
  font-size: 13px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-left: 60%;
  /* position: relative;
  flex: 1 1 auto; */
}

.detail button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detail button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.detail button i {
  margin-right: 5px;
}

.detail .control-label div {
  display: inline;
}

.detail .control-label div.v-if {
  color: red;
}

.detail .actions {
  margin-top: 20px;
}

.control-label {
  padding-top: 10px;
  padding-right: 5px;
  margin-bottom: 0;
  text-transform: capitalize !important;
  position: relative;
  padding-left: 14px;
  display: inline-block;
  color: #2b3656;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.01rem;
  font-size: 15px;
}

.myhr {
  width: 100%;
  border: 0.5px solid #ddd;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

hr.dashed {
  border: 0;
  height: 1px;
  border-top: 1px dashed red; /* 设置虚线样式和颜色 */
}

@media (max-width: 768px) {
  .detail {
    padding-left: 0px;
  }
  .add-btn {
    padding: 5px 10px;
    padding-top: 2px !important;
  }
  .detail .control-label {
    width: 30%;
    padding-left: 0px;
    padding-right: 2px;
    font-size: 12px !important;
  }

  .detail .box-body {
    width: 70%;
    font-size: 12px !important;
  }
  .detail .box-body input {
    width: 80%;
  }
}
</style>
