<template>
  <div class="config-form">
    <h1>参数配置</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="id">*备案号</label>
        <input type="text" id="id" v-model="formData.id" required />
      </div>
      <div class="form-group">
        <label for="content">*使用授权书</label>
        <quill-editor
          v-model:content="formData.content"
          ref="myQuillEditor" 
          :options="editorOptions"
          @change="onEditorChange($event)"
        ></quill-editor>
        <!-- content 为数据库返回的带有html标签的内容，即提交时候的content -->
      <!-- <div v-html="formData.content">
      </div> -->
      </div>
      <div class="buttons">
        <button type="button" @click="resetForm" class="reset-btn">
          <i class="fas fa-undo"></i> 重置
        </button>
        <button type="submit" class="submit-btn">
          <i class="fas fa-save"></i> 提交
        </button>
      </div>
    </form>
    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
  </div>
</template>

<script setup>
import { ref,onMounted } from "vue";
import { quillEditor } from "vue3-quill"; // 从 'vue3-quill' 导入 quillEditor
import CustomAlert from "@/components/CustomAlert.vue";
import { useStore } from "vuex";
import axios from "@/axios";
import { useRouter } from "vue-router";

const store = useStore();
const showAlert = ref(false);
const alertMessage = ref("");
const alertType = ref("");
const router = useRouter();

const formData = ref({
  id: "",
  content: "",
});

const editorOptions = {
  placeholder: "请输入使用授权书...",
  theme: "snow",
  modules:{
                toolbar:[
                          ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                          ['blockquote', 'code-block'],     //引用，代码块
                          [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                          [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                          [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
                          [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                          [{ 'direction': 'rtl' }],             // 文本方向
                          [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                          [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                          [{ 'font': [] }],     //字体
                          [{ 'align': [] }],    //对齐方式
                          ['clean'],    //清除字体样式
                          ['image','video']    //上传图片、上传视频
                          ]
                      }
};

const resetForm = () => {
  formData.value = {
    id: "",
    content: "",
  };
};


const fetchTableData = async () => {
  try {
    const response = await axios.get("/parameter");
    if (response.status == 200) {
      if (response.data != null) {
        if(response.data!=null){
           formData.value.id = new String(response.data.id);
          //  document.getElementsByClassName(".ql-editor .ql-blank").innerHTML=response.data.content;
           formData.value.content = response.data.content;
        }
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }
};

onMounted(fetchTableData);


const onEditorChange = (editor) => {
      formData.value.content = editor.html
}

const showCustomAlert = (message, type = "info") => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const handleSubmit = async() => {
  // 处理提交逻辑
    try {
    const response = await axios.post("/parameter", formData.value);
    if (response.status == 200) {
      showCustomAlert(response.data.message, "success");
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "提交失败", "error");
    console.error("提交失败:", error);
    if (error.response.data.error == "登录过期") {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
    }
  }


};
</script>

<style scoped>
.config-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  margin: 0;
  color: #333;
  font-family: Montserrat, Nunito, sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.3;
  color: #2c2c2c;
}

.form-group {
  margin-bottom: 1.5rem;
  font-size: 15px;
  font-family: Nunito, Montserrat, system-ui, BlinkMacSystemFont, -apple-system,
    sans-serif;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 15px;
}

input[type="text"] {
  width: 80%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.quill-editor {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 200px;
  height: 300px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-btn {
  background-color: #f44336;
  color: white;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
}

button i {
  margin-right: 0.5rem;
}

    .editor {
      line-height: normal !important;
      height: 800px;
    }
    .ql-snow .ql-tooltip[data-mode=link]::before {
      content: "请输入链接地址:";
    }
    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: '保存';
        padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode=video]::before {
        content: "请输入视频地址:";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
      content: '14px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
      content: '10px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
      content: '18px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
      content: '32px';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
      content: '文本';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: '标题1';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: '标题2';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: '标题3';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: '标题4';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: '标题5';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: '标题6';
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
      content: '标准字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
      content: '衬线字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
      content: '等宽字体';
    }
    .ql-align-center{
      text-align: center;
    }
    .ql-align-right{
      text-align: right;
    }
    .ql-align-left{
      text-align: left;
    }

</style>
