<template>
  <div>
    <div
      id="main"
      v-if="showHeaderAndSidebar"
      :class="{ 'menu-open': isMenuOpen, 'menu-collapsed': isMenuCollapsed }"
    >
      <Sidebar
        id="sidebar"
        :isOpen="isMenuOpen"
        :isCollapsed="isMenuCollapsed"
        @toggleMenu="toggleMenu"
      />
      <div class="main-container">
        <Header
          id="header"
          :username="username"
          :isOnline="isOnline"
          @logout="logout"
          @toggleMenu="toggleMenu"
        />
        <main @click="closeMenuOnMobile">
          <router-view />
        </main>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import Header from "./components/PageHeader.vue";
import Sidebar from "./components/PageSidebar.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    Sidebar,
    Header,
  },
  setup() {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const isMenuCollapsed = ref(false);
    const username = ref("管理员");
    const isOnline = ref(true);

    const showHeaderAndSidebar = ref(false);

    const store = useStore();

    const toggleMenu = () => {
      if (window.innerWidth <= 768) {
        isMenuOpen.value = !isMenuOpen.value;
      } else {
        isMenuCollapsed.value = !isMenuCollapsed.value;
      }
    };

    const closeMenuOnMobile = () => {
      if (window.innerWidth <= 768 && isMenuOpen.value) {
        isMenuOpen.value = false;
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 768) {
        isMenuOpen.value = false;
      }
    };

    onMounted(() => {
       if (window.innerWidth <= 768) {
          isMenuOpen.value = false;
       }else{
         isMenuOpen.value = true;
       }
      window.addEventListener("resize", handleResize);
      const loggedIn = localStorage.getItem("loggedIn");
      if (loggedIn != null) {
        showHeaderAndSidebar.value = true;
      } 
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const logout = () => {
      localStorage.removeItem("loggedIn");
      store.dispatch("logout");
      router.push("/login");
      window.location.reload();
    };

    return {
      isMenuOpen,
      isMenuCollapsed,
      username,
      isOnline,
      toggleMenu,
      closeMenuOnMobile,
      showHeaderAndSidebar,
      logout,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.css";

#main {
  display: flex;
  /* height: 100vh; */
  font-family: "Roboto", sans-serif;
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
  overflow: hidden;
}

main {
  flex: 1;
  /* padding: 20px; */
  background-color: #f4f4f4;
}

.menu-open .main-container {
  margin-left: 250px;
}

.menu-collapsed .main-container {
  margin-left: 60px;
}

@media (max-width: 768px) {
  .menu-open .main-container {
    margin-left: 0;
  }
  .menu-collapsed .main-container {
    margin-left: 0;
  }
}
</style>
