<template>
  <div class="full">
     <div class="content">
      <h1>欢迎登录，管理员</h1>
      <br>
      <br>
      <p>请选择菜单栏去进行操作.</p>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.full{
  height:600px;
  font-family: Montserrat, Nunito, sans-serif;
  background: linear-gradient(135deg, white, hsl(0, 8%, 90%));
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(206, 87, 87, 0.1);
}
.content {
  text-align: center;
  color: rgb(15, 13, 13);
}

h1 {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 1.5rem;
}

p {
  font-size: 1.3rem;
}
</style>
