import axios from 'axios';
import store from './store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api', // 默认URL，可以在环境变量中配置'/api'
  timeout: 5000, // 请求超时设置
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    // 在发送请求之前做些什么，比如添加token
    config.headers.Authorization = `${token}`;
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
